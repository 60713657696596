<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">System Configuration</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Setting Modules
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Manage Setting Modules
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-sm-0 tx-right col-lg-7">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newSetting',
              mode: 'create',
            })
          "
        >
          New Setting
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select name="size">
            <option value="1000">1000</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" class="float-right" placeholder="Search..." />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-25p">Setting Title</th>
              <th class="wd-40p">Description</th>
              <th class="wd-10p">Toggle ID</th>
              <th class="wd-10p">Status</th>
              <th class="wd-10p text-right table-end-item">Action</th>
            </tr>
          </thead>
          <tbody v-for="(setting, index) in dataLists" :key="index">
            <tr v-if="!removeList.includes(setting.id)">
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{ setting.title }}</td>
              <td>{{ setting.information }}</td>
              <td>{{ setting.toggle_id }}</td>
              <td>{{ setting.status }}</td>
              <td class="text-right table-end-item">
                <a
                  href="javascript:;"
                  class="mr-2"
                  @click="edit('newSetting', setting.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" @click="drop(setting.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Create />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Create from "./createSetting";
export default {
  components: {
    Create,
  },
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/setting/${id}`);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.$store.commit("getData", `api/setting`);
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>